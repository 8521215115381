<template>
  <div>
    <b-row>
      <b-col
        class="d-none d-lg-block"
        cols="2"
      >
        <product-filter
          :filter-display="filterDisplay"
          :filter="filterSelections"
          :constraints="searchFilter.constraints"
          @clear-filter="searchFilter.search = ''; filterSelections = {}; searchFilter.constraints = { dateType: 'None', dateTo: '', dateFrom: '' }"
          @filter-change="updateFromFilter"
          @constraint-change="updateFromConstraint"
        />
      </b-col>

      <b-col
        cols="12"
        lg="10"
      >
        <!-- ECommerce Header -->
        <section id="ecommerce-header">
          <div class="row">
            <div class="col-sm-12">
              <div class="ecommerce-header-items">
                <div class="result-toggler">
                  <b-button
                    variant="primary"
                    class="d-inline d-lg-none"
                    @click="filterSidebar = !filterSidebar"
                  >
                    <feather-icon
                      icon="FilterIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Filter</span>
                  </b-button>
                  <div class="search-results">
                    {{ totalRows }} results found <span v-if="!productLoading && totalRows > perPage">| Showing results {{ `from ${showing[0]} to ${showing[1]}` }}</span>
                  </div>
                </div>
                <div class="view-options d-flex">
                  <b-button
                    v-if="createPortfolioModal.newPortfolioId"
                    variant="primary"
                    @click="$router.push({name: 'portfolio-report', params: {portfolioId: createPortfolioModal.newPortfolioId}}); createPortfolioModal.newPortfolioId = null;"
                  >
                    View Portfolio
                  </b-button>
                  <template v-else>
                    <b-button
                      variant="primary"
                      :disabled="productLoading || !totalRows"
                      @click="showPortfolioReport(false)"
                    >
                      Analyse Results
                    </b-button>
                  </template>
                  <b-button
                    variant="primary"
                    class="ml-1"
                    :disabled="productLoading || !totalRows"
                    @click="addProductsToPortfolio.show = true"
                  >
                    Add To Portfolio
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Searchbar -->
        <div class="ecommerce-searchbar mt-1 mb-2">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchFilter.search"
              placeholder="Search (ID, name or identifier)"
              class="search-product"
              @change="doSearch(); updateFilters();"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </div>

        <!-- Pagination Result Label for Mobile -->
        <div class="d-block d-lg-none mb-1 font-weight-bold">
          {{ totalRows }} results found <span v-if="!productLoading && totalRows > perPage">| Showing results {{ `from ${showing[0]} to ${showing[1]}` }}</span>
        </div>

        <b-card
          id="product-table"
          no-body
        >
          <b-table
            v-if="!productError"
            responsive
            hover
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :no-local-sorting="true"
            @row-clicked="productClicked"
            @row-middle-clicked="productClicked"
            @row-contextmenu="handleContext"
          >
            <template #cell(toggle)="data">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon rounded-circle"
                @click.prevent="data.toggleDetails"
              >
                <feather-icon :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
              </b-button>
            </template>

            <template #cell(producttype)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-1"
                  :variant="data.item.pticoncolor"
                >
                  <feather-icon
                    size="18"
                    :icon="data.item.pticonname"
                  />
                </b-avatar>
                <span>{{ data.item.producttypename }}</span>
              </div>
            </template>

            <template #cell(numportfolios)="data">
              <b-avatar
                variant="light-success"
                @click.prevent="console.log('add clicked')"
              >
                <b-button
                  variant="outline-success"
                  @click.prevent="addProductToPortfolio.product=data.item; addProductToPortfolio.show = true;"
                >
                  <template v-if="data.item.numportfolios > 0">
                    {{ data.item.numportfolios.toString() }}
                  </template>
                  <feather-icon
                    v-else
                    size="18"
                    icon="PlusIcon"
                  />
                </b-button>
              </b-avatar>
            </template>

            <template #row-details="row">
              <b-card class="m-0 p-0">
                <b-row>
                  <b-col lg="6">
                    <b-table
                      responsive
                      :hover="false"
                      :thead-class="'d-none'"
                      :fields="dropdownGeneralFields"
                      :items="row.item.dropdownterm === '' ? [] : JSON.parse(row.item.dropdownterm).productterms"
                    />
                  </b-col>
                  <b-col lg="6">
                    <b-table
                      responsive
                      :thead-class="'d-none'"
                      :fields="dropdownGeneralFields"
                      :items="row.item.dropdownterm === '' ? [] : JSON.parse(row.item.dropdownterm).coverinfo"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="m-0 p-0">
                <b-table
                  responsive
                  :hover="false"
                  :fields="dropdownUnderlyingFields"
                  :items="row.item.dropdownterm === '' ? [] : JSON.parse(row.item.dropdownunderlying).content"
                />
              </b-card>
            </template>
          </b-table>
          <error-display
            v-else
            @action-clicked="doSearch"
          />

          <div
            v-if="productLoading"
            class="w-100 text-center py-5 d-flex flex-column justify-content-center align-items-center"
            :style="`height: ${loadingContainerHeight}`"
          >
            <b-spinner variant="primary" />
          </div>
          <no-data-container
            v-else-if="items.length < 1 && !productError"
            message="No Products Matched Your Search"
          />

          <div class="d-flex justify-content-between px-2">
            <div class="my-2">
              <span class="pb-1">Rows Per Page</span>
              <b-form-select
                v-model="perPage"
                :options="perPageOptions"
                class="pagination-select"
              />
            </div>

            <div class="my-2 d-flex flex-column justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="lg"
                class="mb-0"
              />
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-sidebar
      v-model="filterSidebar"
      :backdrop="true"
      :no-header="true"
      :body-class="filterSidebarClass"
    >
      <div class="p-2">
        <product-filter
          :filter-display="filterDisplay"
          :filter="filterSelections"
          :constraints="searchFilter.constraints"
          @clear-filter="searchFilter.search = ''; filterSelections = {};"
          @filter-change="updateFromFilter"
        />
      </div>
    </b-sidebar>

    <!-- Create Portfolio Modal -->
    <b-modal
      v-if="totalRows <= maxNumberOfProductsInPortfolio"
      v-model="createPortfolioModal.show"
      centered
      :title="`Create Portfolio Using ${totalRows} Products`"
      hide-footer
    >
      <create-portfolio-modal-content
        :portfolios="portfolios"
        :product-ids="allProductIds"
        :view-portfolio="createPortfolioModal.viewAfterCreation"
        description="product"
        @portfolio-created="handlePortfolioCreated"
      />
    </b-modal>
    <b-modal
      v-else-if="createPortfolioModal.virtual"
      v-model="createPortfolioModal.show"
      ok-title="Proceed with Limited Product Set"
      cancel-variant="danger"
      cancel-title="Cancel"
      modal-class="modal-danger"
      centered
      title="Product Per Portfolio Limit Reached"
      @ok="showPortfolioReport(true); createPortfolioModal.virtual = false"
      @hide="createPortfolioModal.virtual = false"
    >
      <b-card-text>
        You can analyse a maximum of {{ maxNumberOfProductsInPortfolio }} products in one portfolio.
        The analysis will include only the first {{ maxNumberOfProductsInPortfolio }} products from your search results.
      </b-card-text>
    </b-modal>
    <b-modal
      v-else
      v-model="createPortfolioModal.show"
      ok-only
      ok-variant="danger"
      ok-title="Close"
      modal-class="modal-danger"
      centered
      title="Product Per Portfolio Limit Reached"
    >
      <b-card-text>
        You can only include {{ maxNumberOfProductsInPortfolio }} products in one portfolio. Try to narrow your search
        to get under the limit.
      </b-card-text>
    </b-modal>

    <!-- Add Product to Portfolio Modal -->
    <b-modal
      v-model="addProductToPortfolio.show"
      centered
      title="Add Product To Portfolio"
      hide-footer
    >
      <add-product-to-portfolio-modal-content
        :product="addProductToPortfolio.product"
        @portfolioUpdated="onProductAddedToPortfolio"
      />
    </b-modal>

    <!-- Add Product<b>s</b> to Portfolio Modal -->
    <b-modal
      v-if="totalRows <= maxNumberOfProductsInPortfolio"
      v-model="addProductsToPortfolio.show"
      centered
      title="Add Products To Portfolio"
      hide-footer
    >
      <add-products-to-portfolio-modal-content
        :portfolios="portfolios"
        :product-ids="allProductIds"
        @products-added="onProductsAddedToPortfolio"
        @create-portfolio="onCreatePortfolio"
      />
    </b-modal>
    <b-modal
      v-else
      v-model="addProductsToPortfolio.show"
      ok-only
      ok-variant="danger"
      ok-title="Close"
      modal-class="modal-danger"
      centered
      title="Product Per Portfolio Limit Reached"
    >
      <b-card-text>
        You can only include {{ maxNumberOfProductsInPortfolio }} products in a portfolio. Try to narrow your search
        to get under the limit.
      </b-card-text>
    </b-modal>

    <!-- Open in Tab Context Menu -->
    <open-in-new-tab-dialog
      ref="openInTabContext"
      @open-in-new-tab="openInNewTab"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BTable,
  BAvatar,
  BButton,
  BPagination,
  BFormSelect,
  BSpinner,
  BModal,
  BSidebar,
} from 'bootstrap-vue'
import strings from '@/strings'
import CreatePortfolioModalContent from '@/views/components/CreatePortfolioModalContent.vue'
import NoDataContainer from '@/views/components/NoDataContainer.vue'
import AddProductsToPortfolioModalContent from '@/views/components/AddProductsToPortfolioModalContent.vue'
import AddProductToPortfolioModalContent from '@/views/components/AddProductToPortfolioModalContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import OpenInNewTabDialog from '@/views/components/OpenInNewTabDialog.vue'
import ProductFilter from './components/ProductFilter.vue'
import ErrorDisplay from './components/ErrorDisplay.vue'

export default {
  name: 'Products',
  components: {
    AddProductToPortfolioModalContent,
    AddProductsToPortfolioModalContent,
    NoDataContainer,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BTable,
    BAvatar,
    BButton,
    BPagination,
    BFormSelect,
    BSpinner,
    BModal,
    BSidebar,
    ProductFilter,
    CreatePortfolioModalContent,
    ErrorDisplay,
    OpenInNewTabDialog,
  },
  directives: {
    Ripple,
  },
  data() {
    // Total Width: 1300
    // ID:
    return {
      filterSidebar: false,
      filterSelections: {},
      initialLoad: true,
      clickedProductId: '',
      fields: [
        // Dropdown hidden
        // { key: 'toggle', label: 'Show Details', thStyle: { width: '5%' } },
        {
          key: 'productid', label: 'Id', thStyle: { width: '11%' }, sortable: true,
        },
        {
          key: 'producttype', label: 'Type', thStyle: { width: '16%' }, sortable: true,
        },
        {
          key: 'productname', label: 'Name', thStyle: { width: '40' }, sortable: true,
        },
        {
          key: 'date_1_strike', label: 'Strike Date', thStyle: { width: '13%' }, sortable: true,
        },
        {
          key: 'date_2_finalmarket', label: 'Final Date', thStyle: { width: '13%' }, sortable: true,
        },
        {
          key: 'numportfolios', label: 'Port-folios', thStyle: { width: '5%' },
        },
      ],
      items: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterDisplay: [],
      searchFilter: {
        search: '',
        filter: {},
        constraints: {
          dateFrom: '',
          dateTo: '',
          dateType: 'None',
        },
      },
      dropdownGeneralFields: ['field', 'value'],
      dropdownUnderlyingFields: [{
        label: 'Underlying Name',
        key: 'underlying',
      }, {
        label: '% of Initial Value',
        key: 'relvalue',
      }, {
        label: 'Status',
        key: 'status',
      }],
      currentPage: 1,
      perPage: 20,
      previousPage: null,
      nextPage: null,
      totalPages: 0,
      totalRows: 0,
      perPageOptions: [5, 10, 20, 50, 100, 500],
      productLoading: false,
      loadingContainerHeight: '200px',
      allProductIds: [],
      createPortfolioModal: {
        show: false,
        virtual: false,
        newPortfolioId: null,
        viewAfterCreation: false,
      },
      addProductToPortfolio: {
        show: false,
        loading: false,
        saveLoading: false,
        product: null,
        portfolios: [],
        in: [],
        out: [],
      },
      addProductsToPortfolio: {
        show: false,
      },
      productError: null,
      cancelToken: undefined,
    }
  },
  computed: {
    showing() {
      const start = this.previousPage ? (this.currentPage * this.perPage) - this.perPage + 1 : 1
      const end = (start === 1 ? 0 : start - 1) + this.items.length
      return [start, end]
    },
    portfolioIn() {
      return this.addProductToPortfolio.portfolios.filter(portfolio => portfolio.in !== false)
    },
    portfolioNotIn() {
      return this.addProductToPortfolio.portfolios.filter(portfolio => portfolio.in === false)
    },
    maxNumberOfProductsInPortfolio() {
      return localStorage.getItem('maxNumberOfProductsInPortfolio') || this.$maxPortfolioSize
    },
    portfolios() {
      return this.$store.state.app.portfolios
    },
    filterSidebarClass() {
      return this.$store.state.appConfig.layout.skin === 'dark' ? 'filter-sidebar-dark-bg custom-scrollbar' : 'filter-sidebar-light-bg custom-scrollbar'
    },
  },
  watch: {
    currentPage() {
      this.doSearch()
    },
    perPage() {
      if (this.currentPage === 1) {
        this.doSearch()
      } else {
        this.currentPage = 1
      }
      window.scrollTo(0, 0)
    },
    sortBy() {
      this.doSearch()
    },
    sortDesc() {
      this.doSearch()
    },
  },
  activated() {
    this.doSearch(this.initialLoad)
    this.initialLoad = false
  },
  deactivated() {
    this.createPortfolioModal.newPortfolioId = null
  },
  mounted() {
    this.loadFilters()
  },
  methods: {
    productClicked(item, index, event) {
      this.clickedProductId = item.productid

      if (event.ctrlKey || event.button === 1) {
        const route = this.$router.resolve({ name: 'product-report', params: { productId: this.clickedProductId } })
        window.open(route.href, '_blank')
      } else {
        this.$router.push({ name: 'product-report', params: { productId: this.clickedProductId } })
      }
    },
    handleContext(item, index, event) {
      event.preventDefault()
      this.$refs.openInTabContext.open(event, item)
    },
    openInNewTab(item) {
      const route = this.$router.resolve({ name: 'product-report', params: { productId: item.productid } })
      window.open(route.href, '_blank')
    },
    showPortfolioReport(reduce = false) {
      if ((this.totalRows > this.maxNumberOfProductsInPortfolio) && !reduce) {
        this.createPortfolioModal.virtual = true
        this.createPortfolioModal.show = true
        return
      }

      // Include limit and sort params inside searchFilter for virtual portfolio
      const virtualSearchFilter = {
        ...this.searchFilter,
        reduceToLimit: reduce,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      }

      this.$router.push({
        name: 'portfolio-report',
        params: {
          portfolioId: 'v',
          searchFilter: virtualSearchFilter,
        },
        query: {
          src: this.$route.name,
        },
      })
    },
    updateFromFilter(newFilter) {
      this.searchFilter.filter = newFilter
      this.updateFilters()
      this.doSearch()
    },
    updateFromConstraint(newConstraints) {
      this.searchFilter.constraints = newConstraints
      this.updateFilters()
      this.doSearch()
    },
    doSearch(showLoading = true) {
      // Abort previous requests
      // Check if there are any previous pending requests
      if (typeof this.cancelToken !== typeof undefined) {
        this.cancelToken.cancel('Operation canceled due to new request.')
        this.productLoading = true
      }

      this.cancelToken = axios.CancelToken.source()

      // Get height of product table card
      const productTable = document.getElementById('product-table')
      const height = productTable ? productTable.offsetHeight : 0

      if (showLoading) {
        this.productLoading = true
        this.loadingContainerHeight = height ? `${(height - 177)}px` : '200px'
        this.items = []

        // Remove newPortfolioId value to show 'Analyse Result' button instead of 'View Portfolio' button
        this.createPortfolioModal.newPortfolioId = null
      }

      // Reset error flag
      this.productError = null

      this.$http
        .get('data.cfc?method=getFilterResults', {
          params: {
            searchFilter: { ...this.searchFilter, sortBy: this.sortBy, sortDesc: this.sortDesc },
            perpage: this.perPage,
            page: this.currentPage,
          },
          cancelToken: this.cancelToken.token,
        })
        .then(response => {
          this.items = response.data.DATA
          this.previousPage = response.data.INFO.PREVIOUSPAGE
          this.nextPage = response.data.INFO.NEXTPAGE
          this.currentPage = response.data.INFO.CURRENTPAGE * 1
          this.totalPages = response.data.INFO.NUMPAGES
          this.perPage = response.data.INFO.PERPAGE
          this.totalRows = response.data.INFO.NUMROWS
          this.allProductIds = response.data.INFO.ALLPRODUCTIDS

          this.cancelToken = undefined
          this.productLoading = false
        })
        .catch(error => {
          if (error.response || error.request) {
            console.log(error)
            this.productError = true
            this.productLoading = false
          }
        })
    },
    loadFilters() {
      this.$http
        .get('data.cfc?method=getFilterDisplay')
        .then(response => {
          this.filterDisplay = response.data

          // Index the filter data so that items can be looked up by value
          this.filterDisplay.forEach(category => {
            // eslint-disable-next-line no-param-reassign
            category.valueIndex = {}
            category.items.forEach((item, index) => {
              // eslint-disable-next-line no-param-reassign
              category.valueIndex[item.value] = index
            })
          })
        })
    },
    updateFilters() {
      this.$http
        .get('data.cfc?method=getFilterDisplay', {
          params: {
            searchFilter: this.searchFilter,
          },
        })
        .then(response => {
          // Zero out the counts
          this.filterDisplay.forEach(category => {
            // Need to use map instead of forEach, to force reactive update of sub-components
            // eslint-disable-next-line no-param-reassign
            category.items = category.items.map(item => {
              // eslint-disable-next-line no-param-reassign
              item.count = 0
              return item
            })
          })

          // Merge the updated counts into the existing filter data
          response.data.forEach((category, categoryIndex) => {
            category.items.forEach(item => {
              const itemIndex = this.filterDisplay[categoryIndex].valueIndex[item.value]
              this.filterDisplay[categoryIndex].items[itemIndex].count = item.count
            })
          })
        })
    },
    createPortfolio() {
      this.createPortfolioModal.loading = true
      this.createPortfolioModal.validationError = null

      // Check if the portfolio name is already used
      const nameExists = this.portfolios.find(portfolio => portfolio.portfolioName === this.createPortfolioModal.portfolioName)

      if (nameExists) {
        this.createPortfolioModal.loading = false
        // Set input error message
        this.createPortfolioModal.validationError = 'Portfolio Name Already Exists. Try Another One.'
        return
      }

      this.$http.post('portfolio.cfc?method=createPortfolio', new URLSearchParams({
        portfolioName: this.createPortfolioModal.portfolioName,
        productIds: this.allProductIds,
      })).then(() => {
        this.$bvToast.toast('Portfolio Created', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.createPortfolioModal.portfolioName = ''
        this.createPortfolioModal.show = false

        // Update store value
        this.$store.dispatch('app/updatePortfolios')
        this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
      }).catch(error => {
        this.$bvToast.toast(strings.defaultErrorMessage, {
          title: strings.defaultErrorTitle,
          variant: 'danger',
          solid: true,
        })
        console.log('Error creating portfolio: ', error)
      }).then(() => {
        this.createPortfolioModal.loading = false
      })
    },
    loadPortfoliosByProduct(id) {
      this.addProductToPortfolio.loading = true

      this.$http.get(`portfolio.cfc?method=getPortfoliosByProduct&productId=${id}`)
        .then(response => {
          // Sort by portfolios `in` property
          const productPortfolios = response.data.portfolios
          productPortfolios.sort((a, b) => {
            if (a.in && !b.in) {
              return -1
            }
            return 1
          })
          this.addProductToPortfolio.portfolios = productPortfolios
        }).catch(() => {
          this.$bvToast.toast('Fetching Portfolios Failed', {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        }).then(() => {
          this.addProductToPortfolio.loading = false
        })
    },
    updateProductPortfolio() {
      this.addProductToPortfolio.saveLoading = true

      this.$http.post('portfolio.cfc?method=replacePortfoliosByProduct', new URLSearchParams({
        productId: this.addProductToPortfolio.product.productid,
        portfolioIds: this.portfolioIn.map(p => p.portfolioId),
      }))
        .then(() => {
          this.$bvToast.toast('Product Portfolio Updated', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.addProductToPortfolio.show = false

          // Reload product table data to show correct number of portfolios on the table
          this.doSearch(false)

          // Update store value
          this.$store.dispatch('app/updatePortfolios')
          this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
        })
        .catch(() => {
          this.$bvToast.toast(strings.defaultErrorMessage, {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        })
        .then(() => {
          this.addProductToPortfolio.saveLoading = false
        })
    },
    handlePortfolioCreated(portfolio) {
      this.createPortfolioModal.show = false
      // this.createPortfolioModal.newPortfolioId = portfolioId

      this.$bvToast.toast('Portfolio Created', {
        title: 'Success',
        variant: 'success',
        solid: true,
      })

      // Reload product table data to show correct number of portfolios on the table
      this.doSearch(false)

      // Update portfolios list inside the store
      this.$store.dispatch('app/updatePortfolios')
      this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
      this.$store.commit('app/ADD_UPDATED_PORTFOLIO', portfolio.portfolioId)

      // Navigate to portfolio report page
      if (portfolio.viewPortfolio) {
        this.$router.push({ name: 'portfolio-report', params: { portfolioId: portfolio.portfolioId } })
      }
    },
    onProductsAddedToPortfolio(portfolio) {
      this.addProductsToPortfolio.show = false
      this.$bvToast.toast('Products Added to Portfolio', {
        title: 'Success',
        variant: 'success',
        solid: true,
      })

      // Reload product table data to show correct number of portfolios on the table
      this.doSearch(false)

      // Update portfolios list inside the store
      this.$store.dispatch('app/updatePortfolios')
      this.$store.commit('app/ADD_UPDATED_PORTFOLIO', portfolio.portfolioId)

      // Navigate to portfolio report page
      if (portfolio.viewPortfolio) {
        this.$router.push({ name: 'portfolio-report', params: { portfolioId: portfolio.portfolioId } })
      }

      // Set portfolio ID for 'View Portfolio' button. This will change the 'Analyse Results' button to 'View Portfolio'
      // this.createPortfolioModal.newPortfolioId = portfolioId
    },
    onProductAddedToPortfolio(portfolioIds) {
      this.addProductToPortfolio.show = false

      this.$bvToast.toast('Product Portfolio Updated', {
        title: 'Success',
        variant: 'success',
        solid: true,
      })

      // Reload product table data to show correct number of portfolios on the table
      this.doSearch(false)

      // Update store value
      this.$store.dispatch('app/updatePortfolios')
      this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
      this.$store.commit('app/ADD_UPDATED_PORTFOLIOS', portfolioIds)
    },
    onCreatePortfolio(viewAfterCreation) {
      this.addProductsToPortfolio.show = false
      this.createPortfolioModal.show = true
      this.createPortfolioModal.viewAfterCreation = Boolean(viewAfterCreation)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

.pagination-select {
  max-width: 200px;
}
</style>
