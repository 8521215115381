<template>
  <div class="d-flex flex-column pb-2">
    <h5>Select a portfolio</h5>

    <p class="text-muted">
      All the products in the current results will be added to the selected portfolio. You can add additional products or remove any of them later.
    </p>

    <div class="my-1">
      <b-form-radio
        v-model="selectedPortfolio"
        name="portfolio"
        :value="-1"
        class="pb-2"
      >Create New Portfolio</b-form-radio>
      <b-form-radio
        v-for="portfolio in portfolios"
        :key="portfolio.portfolioId"
        v-model="selectedPortfolio"
        name="portfolio"
        :value="portfolio.portfolioId"
        class="pb-0-5"
      >{{ portfolio.portfolioName }}</b-form-radio>
    </div>

    <BButton
      variant="primary"
      class="d-flex justify-content-center"
      :disabled="loading || !selectedPortfolio"
      @click.prevent="selectedPortfolio === -1 ? $emit('create-portfolio') : addProductsToPortfolio()"
    >
      Add To Portfolio
      <b-spinner
        v-if="loading === 1"
        class="ml-1 align-self-center"
        small="true"
      />
    </BButton>

    <span class="d-block text-center small mb-1" />

    <BButton
      variant="primary"
      class="d-flex justify-content-center"
      :disabled="loading || !selectedPortfolio"
      @click.prevent="selectedPortfolio === -1 ? $emit('create-portfolio', true) : addProductsToPortfolio(true)"
    >
      Add To Portfolio and View Portfolio
      <b-spinner
        v-if="loading === 2"
        class="ml-1 align-self-center"
        small="true"
      />
    </BButton>
  </div>
</template>

<script>
import {
  BFormRadio,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import strings from '@/strings'

export default {
  components: {
    BFormRadio,
    BButton,
    BSpinner,
  },
  props: {
    portfolios: {
      type: Array,
      required: true,
    },
    productIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPortfolio: null,
      loading: false,
    }
  },
  computed: {
    maxNumberOfProductsInPortfolio() {
      return localStorage.getItem('maxNumberOfProductsInPortfolio') || this.$maxPortfolioSize
    },
  },
  methods: {
    addProductsToPortfolio(viewPortfolio = false) {
      this.loading = viewPortfolio ? 2 : 1

      this.$http.post('portfolio.cfc?method=addProductsToPortfolio', new URLSearchParams({
        portfolioId: this.selectedPortfolio,
        productIds: this.productIds,
      }))
        .then(() => {
          this.$emit('products-added', {
            portfolioId: this.selectedPortfolio,
            viewPortfolio,
          })
          this.selectedPortfolio = null
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            this.$bvToast.toast('Portfolio Product Limit Reached', {
              title: strings.defaultErrorTitle,
              variant: 'danger',
              solid: true,
            })
          } else {
            this.$bvToast.toast(strings.defaultErrorMessage, {
              title: strings.defaultErrorTitle,
              variant: 'danger',
              solid: true,
            })
          }
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.pb-0-5 {
  padding-bottom: 6px;
}
</style>
