<template>
  <div>
    <b-row>
      <b-col
        class="d-flex justify-content-between filter-heading-container"
        cols="12"
      >
        <h6 class="filter-heading ps-1 mb-0 d-flex flex-column justify-content-center">
          Filters
        </h6>

        <b-button
          variant="flat-primary"
          size="sm"
          @click="clearFilters()"
        >
          CLEAR
        </b-button>
      </b-col>
    </b-row>

    <!-- Filters' Card -->
    <b-card>
      <div class="mb-2 d-flex flex-column">
        <h6>Date Constraint</h6>
        <v-select
          v-model="constraints.dateType"
          :options="dateTypeOptions"
          :reduce="option => option.value"
          :clearable="false"
          class="mb-1 date-type-select"
        />
        <b-form-datepicker
          v-model="constraints.dateFrom"
          class="mb-1"
          :placeholder="constraints.dateType === 'Live' ? 'As at' : 'From'"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :disabled="constraints.dateType === 'None'"
          :min="dateValidation.dateFrom.min"
          :max="dateValidation.dateFrom.max"
          reset-button
          :reset-value="''"
          label-reset-button="Clear"
          locale="en-CA"
        />
        <b-form-datepicker
          v-if="constraints.dateType !== 'Live'"
          v-model="constraints.dateTo"
          class="mb-1"
          placeholder="To"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :disabled="constraints.dateType === 'None'"
          :min="dateValidation.dateTo.min"
          :max="dateValidation.dateTo.max"
          reset-button
          :reset-value="''"
          label-reset-button="Clear"
          locale="en-CA"
        />
      </div>

      <product-filter-category
        v-for="category in formattedFilterDisplay"
        :key="category.categoryKey"
        :category="category"
        :filter="filter"
        :clear="clear"
        v-on="$listeners"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import ProductFilterCategory from '@/views/components/ProductFilterCategory.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormDatepicker,
    ProductFilterCategory,
    vSelect,
  },
  props: {
    filterDisplay: {
      type: [Array],
      required: true,
    },
    filter: {
      type: [Object],
      required: true,
    },
    constraints: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      clear: false,
      dateTypeOptions: [
        {
          label: 'None',
          value: 'None',
        },
        {
          label: 'Strike Date',
          value: 'Strike Date',
        },
        {
          label: 'Final Market Date',
          value: 'Final Market Date',
        },
        {
          label: 'Matured',
          value: 'Matured',
        },
        {
          label: 'Live',
          value: 'Live',
        },
      ],
    }
  },
  computed: {
    filterResult() {
      const result = {}
      Object.entries(this.filter).forEach(([key, value]) => {
        if (value.length) {
          result[key] = value
        }
      })
      return result
    },
    formattedFilterDisplay() {
      const formattedFilterDisplay = this.filterDisplay
      formattedFilterDisplay.forEach((c, i) => c.items.forEach((item, y) => {
        formattedFilterDisplay[i].items[y].html = `<span class="d-flex justify-content-between">${item.label} <span class="badge badge-light-primary filter-product-count">${item.count}</span></span>`
      }))
      return formattedFilterDisplay
    },
    dateValidation() {
      const dateValidation = {
        dateTo: {
          min: null,
          max: this.constraints.dateType === 'Matured' ? new Date() : null,
        },
        dateFrom: {
          min: null,
          max: this.constraints.dateType === 'Matured' || this.constraints.dateType === 'Live' ? new Date() : null,
        },
      }

      if (this.constraints.dateTo) {
        dateValidation.dateFrom.max = this.constraints.dateTo
      }

      if (this.constraints.dateFrom) {
        dateValidation.dateTo.min = this.constraints.dateFrom
      }

      return dateValidation
    },
  },
  watch: {
    'constraints.dateType': function (newVal) {
      switch (newVal) {
        case 'None':
          this.constraints.dateTo = ''
          this.constraints.dateFrom = ''
          break
        case 'Matured':
          if (new Date(this.constraints.dateTo) > ((new Date()).setHours(0, 0, 0, 0))) {
            this.constraints.dateTo = ''
          }
          if (new Date(this.constraints.dateFrom) > ((new Date()).setHours(0, 0, 0, 0))) {
            this.constraints.dateFrom = ''
          }
          break
        case 'Live':
          if (new Date(this.constraints.dateFrom) > ((new Date()).setHours(0, 0, 0, 0))) {
            this.constraints.dateFrom = ''
          }
          this.constraints.dateTo = ''
          break
        default:
      }

      this.$emit('constraint-change', this.constraints)
    },
    'constraints.dateFrom': function () {
      this.$emit('constraint-change', this.constraints)
    },
    'constraints.dateTo': function () {
      this.$emit('constraint-change', this.constraints)
    },
  },
  methods: {
    clearFilters() {
      this.filter = {}
      this.$emit('clear-filter', {})
      this.$emit('filter-change', {})
      this.clear = true
      setTimeout(() => {
        this.clear = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-heading-container {
  margin-bottom: 0.5rem;
}

.date-type-select::v-deep > div {
  min-height: 37px;
}
.date-type-select::v-deep > div > div > input {
  height: 0;
}

.date-type-select::v-deep > div > div > span {
  position: static !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
