<template>
  <div
    class="filter-category"
  >
    <div class="d-flex justify-content-between">
      <h6 class="filter-title">
        {{ category.categoryLabel }}
      </h6>
    </div>
    <b-form-group v-if="isLargeCategory">
      <b-form-input
        v-model="search"
        placeholder="Filter"
      />
    </b-form-group>
    <b-form-checkbox-group
      v-model="filter[category.categoryKey]"
      class="filter-checkbox-group"
      :options="items"
      stacked
      @change="$emit('filter-change', filterResult)"
    />
    <div class="text-center pt-1">
      <b-link
        v-if="isLargeCategory && search === ''"
        @click="showMore = !showMore"
      >
        Show {{ showMore ? 'Less' : 'All' }}
      </b-link>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckboxGroup, BFormGroup, BFormInput, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BLink,
  },
  props: {
    category: {
      required: true,
      type: Object,
    },
    filter: {
      type: Object,
      required: true,
    },
    clear: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      search: '',
      showMore: false,
    }
  },
  computed: {
    isLargeCategory() {
      return this.category.items.length > 15
    },
    filterResult() {
      const result = {}
      Object.entries(this.filter).forEach(([key, value]) => {
        if (value.length) {
          result[key] = value
        }
      })
      return result
    },
    items() {
      if (this.search) {
        return this.category.items.filter(item => item.label.toLowerCase().search(this.search.toLowerCase()) !== -1)
      } if (this.isLargeCategory && !this.showMore) {
        let selected = this.filterResult[this.category.categoryKey]
        selected = selected && selected.length > 0 ? selected : []

        return this.category.items.filter(item => item.pinned || selected.includes(item.value))
      }
      return this.category.items
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.search = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-heading {
  margin-top: 0.85rem;
  margin-bottom: 1.75rem;
}

.filter-category{
  margin-top: 1rem;

  &:first-child {
    margin-top: 0 !important;
  }
}

.filter-title {
  margin-bottom: 1rem;
}

.filter-checkbox-group {
  padding-right: 4px;
  max-height: 400px;
  overflow-y: auto;
}

.filter-checkbox-group::-webkit-scrollbar {
  width: 4px;
  border-radius: 6px;
}

.filter-checkbox-group::-webkit-scrollbar-track {
  background: rgba(115, 103, 240, 0.25);
  border-radius: 6px;
}

.filter-checkbox-group::-webkit-scrollbar-thumb {
  background: rgba(115, 103, 240, 0.85);
}

.filter-checkbox-group::v-deep > .custom-control {
  margin-bottom: 0.75rem;
}

.filter-checkbox-group::v-deep > .custom-control > label {
  width: 100%;
}
</style>

<style lang="scss">
.filter-product-count {
  height: min-content;
}
</style>
